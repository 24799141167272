@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&family=Sen:wght@400;700;800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .icon-app {
    width: 15px;
    height: 15px;
    object-fit: contain;
  }

  .image-cover {
    object-fit: cover;
    width: 100%;
    height: auto;
    object-position: center;
  }
}

.slick-active.slick-center .item__slick {
  scale: 1.15;
}

.slick-track {
  width: fit-content;
}

.slick-active.slick-center.slick-slide {
}
.slick-list {
  padding: 35px 0 !important;
  margin: 0 -10px;
}
/* CUSTOM PROGRESS BAR */
.progress {
  /* background: linear-gradient(to right, #82CFD0 0%, #82CFD0 40%, #fff 40%, #fff 100%); */
  border-radius: 8px;
  max-width: 100px;
  height: 5px;
  outline: none;
  transition: background 450ms ease-in;
  -webkit-appearance: none;
}
.progress-primary::-webkit-slider-thumb {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  -webkit-appearance: none;
  cursor: ew-resize;
  background: #bc9a3b;
  position: relative;
  outline: 1px solid #bc9a3b;
  outline-offset: 2px;
}
.progress-black::-webkit-slider-thumb {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  -webkit-appearance: none;
  cursor: ew-resize;
  background: #383838;
  position: relative;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  overflow-x: hidden;
}

code {
  font-family: 'Inter', sans-serif
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

#root {
  height: 100vh;
}
